import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { TableCell, TableRow, Table, TableBody, Chip, Tooltip } from '@material-ui/core';
import FlightIcon from '@material-ui/icons/Flight';
import InfoIcon from '@material-ui/icons/Info';
import { checkApiStatus } from '../../utils_v2';
import { globalGetService } from '../../../globalServices';
import { imgStoragePath, displayDateFormatShort } from '../../../constants'
import { assetTypeLabel, assetTypeKey, assetValueKey } from '../'
import { getLocalStorageInfo } from '../../../utils';
import config from '../../../config';
import CamLogo from  '../../../assets/img/camo_assets_icon.svg'
import { castleAirInstance } from '../../../shared/components';
const AssetList = ({fleetType, item, filter, onOpenVariableRent}) => {
    const assetAppRedirect = (e, application) => {
        e.preventDefault();
        e.stopPropagation();
        globalGetService(`console/${assetTypeKey[item.asset_type]}/${item.slug}/`)
        .then(response => {
            if(checkApiStatus(response)){
                const assetDetail = response.data.data;
                if(application === 'records'){
                    if (assetDetail.records) {
                        if (assetDetail.records.technical_records) {
                          window.open(`${config.domain.records}${assetTypeKey[item.asset_type]}/${item.slug}/data-room/technical/${assetDetail.records.technical_records.uuid}${item.archive_status ? '?archived-assets' : ''}`, '_blank')
                        } else if (assetDetail.records.inspection_records) {
                          window.open(`${config.domain.records}${assetTypeKey[item.asset_type]}/${item.slug}/data-room/inspection/${assetDetail.records.inspection_records.uuid}${item.archive_status ? '?archived-assets' : ''}`, '_blank')
                        } else if (assetDetail.records.contract_records) {
                          window.open(`${config.domain.records}${assetTypeKey[item.asset_type]}/${item.slug}/data-room/contract/${assetDetail.records.contract_records.uuid}${item.archive_status ? '?archived-assets' : ''}`, '_blank')
                        } else if (assetDetail.records.contract_records) {
                          window.open(`${config.domain.records}${assetTypeKey[item.asset_type]}/${item.slug}/data-room/discrepancy/${assetDetail.records.contract_records.uuid}${item.archive_status ? '?archived-assets' : ''}`, '_blank')
                        }
                    }
                    else{
                        if(item?.record_folders?.technical_records) {
                            window.open(`${config.domain.records}${assetTypeKey[item?.record_folders?.technical_records.asset_type]}/${item?.record_folders?.technical_records?.asset_slug}/data-room/technical/${item?.record_folders?.technical_records?.uuid}${item.archive_status ? '?archived-assets' : ''}`, '_blank')
                        } else{
                            window.open(`${config.domain.records}assets`,'_blank')
                        }
                    }
                }else if (application === 'lease'){
                    window.open(`${config.domain.lease}${assetTypeKey[item.asset_type]}/${item.slug}/contracts`)
                }else if (application === 'projects'){
                    if (assetDetail?.technical?.work_order_slug){
                        window.open(`${config.domain.projects}${assetTypeKey[item.asset_type]}/${item.slug}/technical/${assetDetail.technical.work_order_slug}/forms/${assetDetail.technical.form_slug}/${assetDetail.technical.form}`, '_blank')
                    }else{ 
                        window.open(`${config.domain.projects}`, '_blank') 
                    }
                }else if (application === 'maintenance'){
                    window.open(`${config.domain.maintenance}${assetTypeKey[item.asset_type]}/${item.slug}/maintenance/${item.asset_type === 1 ? 'airframe':'engines'}`)
                }else if (application === 'camo'){
                    window.open(`${config.domain.camo}${item.id}/${item.asset_type}/${item.asset_type === 1 ? 'amp' : 'ad'}/list`)
                }
            }
        })
    }
    return(
        <TableRow>
            <TableCell>
                <div className='airframe-info' style={{width:'110px'}}>
                    {item.asset_type === 1 && item?.aircraft_type?.name ?<p>{item?.aircraft_type?.name||''} {item.model ? `/${item.model}`:''}</p>:null}
                    {item.asset_type === 2 && item?.engine_type?.name ?<p>{item?.engine_type?.name||''}</p>:null}
                    {item.asset_type === 3 && item?.apu_type?.name ?<p>{item?.apu_type?.name||''}</p>:null}
                    <h4>{`${assetTypeLabel[item.asset_type]} ${item.asset_name}`}</h4>
                    {item?.current_registration_number ? 
                        <p><span>Reg:</span>
                            {item.country ?
                                <><Tooltip title={item.country} arrow><span>{item?.current_registration_number||''}</span></Tooltip></>:
                                <span>{item.current_registration_number}</span>
                            }
                        </p>:null
                    }
                    {item?.ownership_type?.value === 2 && !castleAirInstance ?
                        <>
                            <Chip size="small" color="primary" label="Managed" />
                            {item?.owner_portfolio?.name ?
                                <Tooltip title={item?.owner_portfolio?.name||''} arrow><InfoIcon style={{fontSize:'13px', cursor:'pointer'}}/></Tooltip>:null
                            }
                        </>:null
                    }
                    {
                        castleAirInstance && item?.sun_code ?
                        <p><span style={{fontWeight:"600"}}> Sun Code:</span>
                         {item?.sun_code}</p> : null
                    }
                </div>
            </TableCell>
            {filter.asset_type === 2 ? <TableCell style={{textAlign:"-webkit-center"}}>
                <div style={{width:'80px',}}>
                    <p className='para-text'>{item?.on_wing_msn ||'--'}</p>
                </div>
            </TableCell>:null}
            {
                castleAirInstance ? null :
                <TableCell style={{textAlign:"-webkit-center"}}>
                <div style={{width:'80px'}}>
                    <p className='para-text'>{item?.lessor_name?.name||'--'}</p>
                </div>
            </TableCell>
            }
            {castleAirInstance ?  
            <TableCell>
                  <div style={{width:'80px'}}>
                    <p className='para-text'> {item?.owner_portfolio?.name || '--'}</p>
                </div>
            </TableCell>
            :null }
            { fleetType === 'active' ?
                <TableCell style={{textAlign:"-webkit-center"}}>
                    <div style={{width:'80px'}}>
                        { item?.lessee?.logo ?
                            <img style={{ width: '50px' }} src={item?.lessee?.logo} alt="Lessee logo"></img> :
                            <p className='para-text'>{item?.lessee?.name || '--'}</p>
                        }  
                    </div>
                </TableCell>:null
            }
            
            { getLocalStorageInfo().defaultLessor.id===442 || getLocalStorageInfo().defaultLessor.id===213 ?
                <TableCell style={{textAlign:"-webkit-center"}}>
                    <div style={{width:'80px'}}>
                        {item.next_use?.length ? <Tooltip title={item.next_use} arrow>
                            <p className='para-text'>{item?.next_use?.length > 25 ?  `${item?.next_use.slice(0, 26)}...` : item.next_use}</p>
                        </Tooltip> : '--'}
                    </div>
                </TableCell>:null
            }
            { getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 ) ? null : (filter.asset_type === 0 || filter.asset_type === 1) ?
                <TableCell colSpan={4} style={{borderLeft:'1px solid #d7d7d7', borderRight:'1px solid #d7d7d7', padding:'0'}}>
                    <div style={{width:'100%'}}>
                        <Table style={{width:'100%'}}>
                            <TableBody>
                                { item.asset_type === 1 ?
                                    <TableRow>
                                        <TableCell align='center' style={{width:'110px'}}>
                                            {item?.major_assemblies?.engines.length ?
                                                item?.major_assemblies?.engines.map((engine, index) => 
                                                <div key={`engine_${index}`} style={{marginBottom:'3px', textAlign:'center'}}>
                                                    <Tooltip title={engine.esn} arrow>
                                                        <Chip style={{width:'80px'}} size='small' label={engine.esn.length > 11 ? `${engine.esn.slice(0,10)}...`:engine.esn} variant='outlined' />
                                                    </Tooltip>
                                                </div>):<span className='para-text'>--</span>
                                            }
                                        </TableCell>
                                        {castleAirInstance? null :
                                        <TableCell align='center' style={{width:'110px'}}>
                                            {item?.major_assemblies?.propellers.length ?
                                                item?.major_assemblies?.propellers.map((propeller, index) => 
                                                <div key={`propeller_${index}`} style={{marginBottom:'3px', textAlign:'center'}}>
                                                    <Tooltip title={propeller.serial_number} arrow>
                                                        <Chip style={{width:'90px'}} size='small' label={propeller.serial_number.length > 11 ? `${propeller.serial_number.slice(0,10)}...`:propeller.serial_number} variant='outlined' />
                                                    </Tooltip>
                                                </div>):<span className='para-text'>--</span>
                                            }
                                        </TableCell> }
                                        <TableCell align='center' style={{width:'110px'}}>
                                            {item?.major_assemblies?.landing_gears.length ?
                                                item?.major_assemblies?.landing_gears.map((lg, index) => 
                                                <div key={`lg_${index}`} style={{marginBottom:'3px', textAlign:'center'}}>
                                                    <Tooltip title={lg.serial_number} arrow>
                                                        <Chip style={{width:'80px'}} size='small' label={lg.serial_number.length > 11 ? `${lg.serial_number.slice(0,10)}...`:lg.serial_number} variant='outlined' />
                                                    </Tooltip>
                                                </div>):<span className='para-text'>--</span>
                                            }
                                        </TableCell>
                                        <TableCell align='center' style={{width:'110px'}}>
                                            {item?.major_assemblies?.apus.length ?
                                                item?.major_assemblies?.apus.map((apu, index) => 
                                                <div style={{marginBottom:'3px', textAlign:'center'}}>
                                                    <Tooltip title={apu.serial_number} arrow>
                                                        <Chip style={{width:'80px'}} size='small' label={apu.serial_number.length > 11 ? `${apu.serial_number.slice(0,10)}...`:apu.serial_number} variant='outlined' />
                                                    </Tooltip>
                                                </div>):<span className='para-text'>--</span>
                                            }
                                        </TableCell>
                                    </TableRow>:
                                    <TableRow>
                                        <TableCell style={{width:'350px'}} align='center' colSpan={4}><p className='para-text'>N/A</p></TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </div>
                </TableCell>:null
            }
            { getLocalStorageInfo().defaultLessor.id === 442 ?
                <TableCell style={{textAlign:"-webkit-center"}}>
                    <div style={{width:'90px'}}>
                        {item?.basic_rent_type?.value ? 
                            item?.basic_rent_type?.value === 1 ? 
                            <span className='para-text'>{item.basic_rent}</span>:
                            <span className='para-text'>Variable Rent <Tooltip title='Please click on info icon to view the Range conditions of Variable rent' arrow><InfoIcon onClick={onOpenVariableRent} style={{fontSize:'13px', cursor:'pointer'}} /></Tooltip></span>:
                            <span className='para-text'>--</span>
                        }
                    </div>
                </TableCell>
                :null
            }
            {getLocalStorageInfo().defaultLessor.id !== 442 && !castleAirInstance ?
                <TableCell style={{textAlign:"-webkit-center"}}>
                    <div style={{width:'90px'}}>
                        <p className='para-text'>{item?.owner?.name||'--'}</p>
                    </div>
                </TableCell>:null
            }
            <TableCell style={{textAlign:"-webkit-center"}}>
                <div style={{width:'125px'}}>
                    {item?.status?.label ? <span className={`asset-status status-${item.status.value}`}>{item?.status?.label}</span>:null}
                    {getLocalStorageInfo().defaultLessor.id === 442 ?
                        <div>
                            {item?.status?.value === 1 ?
                                item.end_lease_date ? <p className='para-text sub-status-bg'>{moment(item.end_lease_date).format(displayDateFormatShort)}</p>: null :
                                <p className='para-text sub-status-bg'>{item.previous_lessee && "EX-" + item.previous_lessee}</p>
                            }
                        </div>:
                        <div>
                            {item?.sub_status?.label ? <p className='para-text sub-status-bg'>{item?.sub_status?.label}</p> :null}
                        </div>
                    }
                    {getLocalStorageInfo().defaultLessor.id === 213 && item?.status?.value === 1 ?
                        <p><Tooltip title='' arrow><span style={{fontSize:'11px'}}>{item.contractual_end_date ? moment(item.contractual_end_date).format(displayDateFormatShort) : '--'}</span></Tooltip></p>:null
                    }
                </div>
            </TableCell>
            <TableCell width={130}>
                <div style={{float:'right'}}>
                    <ul className='list-inline ' style={{alignItems:'center', textAlign:'right', width:'140px'}}>
                        <li className='list-inline-item' style={{cursor:'pointer', position:'relative', top:'7px'}}>
                            <Tooltip title='Technical Specification' arrow>
                                <Link to={item.status?.value === 5 ?
                                    `technical-specification/${assetTypeKey[item.asset_type]}/${item.slug}/basic-details?archived-assets`:
                                    `technical-specification/${assetTypeKey[item.asset_type]}/${item.slug}/basic-details`}><FlightIcon fontSize='small' color='primary' /></Link>
                            </Tooltip>
                        </li>
                        { getLocalStorageInfo()?.user?.permission?.contracts ?
                            <li className='list-inline-item' onClick={(e) => assetAppRedirect(e, 'lease')} style={{cursor:'pointer'}}>
                                <Tooltip title='Lease Management' arrow>
                                    <img style={{ width: '15px' }} src={imgStoragePath + 'phase-2/LeaseIcon.svg'} alt="Lease Management" />
                                </Tooltip>
                            </li>:null
                        }
                        { getLocalStorageInfo()?.is_records ?
                            <li className='list-inline-item' onClick={(e) => assetAppRedirect(e, 'records')} style={{cursor:'pointer'}}>
                                <Tooltip title='Records DataRoom' arrow>
                                    <img style={{ width: '15px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Records DataRoom" />
                                </Tooltip>
                            </li>:null
                        }
                        { getLocalStorageInfo()?.is_technical ?
                            <li className='list-inline-item' onClick={(e) => assetAppRedirect(e, 'projects')} style={{cursor:'pointer'}}>
                                <Tooltip title='Projects Management' arrow>
                                    <img style={{ width: '15px' }} src={imgStoragePath + 'phase-2/ProjectsIcon.svg'} alt="Projects Management" />
                                </Tooltip>
                            </li>:null
                        }
                        { getLocalStorageInfo()?.is_maintenance && (item.asset_type === 1 || item.asset_type === 2) ? 
                            <li className='list-inline-item' onClick={(e) => assetAppRedirect(e, 'maintenance')} style={{cursor:'pointer'}}>
                                <Tooltip title='Maintenance Claim Management' arrow>
                                    <img style={{ width: '15px' }} src={imgStoragePath + 'phase-2/MaintenanceIcon.svg'} alt="Maintenance Claim Management" />
                                </Tooltip>
                            </li>:null
                        }
                          { getLocalStorageInfo()?.is_camo && (item.asset_type === 1 || item.asset_type === 2) ? 
                            <li className='list-inline-item' onClick={(e) => assetAppRedirect(e, 'camo')} style={{cursor:'pointer'}}>
                                <Tooltip title='Airworthiness Management' arrow>
                                    <img style={{ width: '15px' }} src={CamLogo} alt="Airworthiness Management" />
                                </Tooltip>
                            </li>:null
                        }
                    </ul>
                </div>
            </TableCell>
        </TableRow>
    )
}
export default AssetList;