import React, { useState } from 'react';
import { Grid, Tabs, Tab, Paper } from '@material-ui/core';
import { AppsCard } from '../components';
import { EmptyCollection } from '../../shared_elements'
import leaseManagementIcon from '../../../shared/assets/img/LeaseIcon.svg';
import projectManagementIcon from '../../../shared/assets/img/ProjectsIcon.svg';
import recordDataroomIcon from '../../../shared/assets/img/RecordsIcom.svg';
import maintenanceManagementIcon from '../../../shared/assets/img/MaintenaceIcon.svg';
import mrCalcIcon from '../../../shared/assets/img/acumen_commverge.png';
import aircraftValuationIcon from '../../../shared/assets/img/valuation_new.png';
import config from '../../../config';
import CamLogo from  '../../../assets/img/CAMO_Final_Logo.svg'
import { getLocalStorageInfo } from '../../utils_v2';
import DeploymentMessage from '../../../shared/components/DeploymentMessage';
const ApplicationsAccess = ({}) => {
    const [tabIndex, setTabIndex] = useState('active');
    const applications = [ 
        {
            label:'Lease Management',
            description:'Effectively manage commercial leases and contracts of the entire fleet - aircraft, engines and even sub-modules through the entire economic life of the asset', 
            logo:leaseManagementIcon, 
            access:getLocalStorageInfo()?.user?.permission?.contracts,
            url:config.domain.lease, 
            isBeta:false
        },
        {
            label:'Projects Management',
            description:'Efficiently plan, execute and manage Technical Projects - from Pre-purchase to Redeliveries.', 
            logo:projectManagementIcon, 
            access:getLocalStorageInfo()?.is_technical || getLocalStorageInfo()?.user?.permission?.technical, 
            url:config.domain.projects, 
            isBeta:false
        },
        {
            label:'Records Dataroom',
            description:'Centralised, secure online Documents Management System (DMS) for digitised aircraft records storage retrieval and collaboration',
            logo:recordDataroomIcon, 
            access:getLocalStorageInfo()?.is_records || getLocalStorageInfo()?.user?.permission?.records, 
            url:config.domain.records, 
            isBeta:false
        },
        {
            label:'Maintenance Claim Management',
            description:'Seamlessly track, execute and manage maintenance events across the fleet, forecast future events and process commercial claims.', 
            logo:maintenanceManagementIcon, 
            access:getLocalStorageInfo()?.is_maintenance || getLocalStorageInfo()?.user?.permission?.maintenance, 
            url:config.domain.maintenance, 
            isBeta:false
        },
        {
            label:'CommVerge',
            description:'A potent decision application for computing commercial exposure and simulating complex scenarios on ~200 aircraft - engine combinations.', 
            logo:mrCalcIcon, 
            access:getLocalStorageInfo()?.is_mr_calculator || getLocalStorageInfo()?.user?.permission?.mr_calculator, 
            url:config.domain.mrCalculator, 
            isBeta:false
        },
        {
            label:'fin-S',
            description:'Access values for a wide variety of aircraft, powered by expertise and experience of ISTAT and ASA Certified Appraisers.', 
            logo:aircraftValuationIcon, 
            access:getLocalStorageInfo()?.is_valuation || getLocalStorageInfo()?.user?.permission?.valuation, 
            url:config.domain.valuation, 
            isBeta:false
        },
        {
            label:'Airworthiness Management',
            description:'Elevate aircraft safety and compliance with our Continuing Airworthiness Management Product. Streamline maintenance, track inspections, and ensure your fleet always soars with confidence.', 
            logo:CamLogo, 
            access:getLocalStorageInfo()?.is_camo || getLocalStorageInfo()?.user?.permission?.camo, 
            url:config.domain.camo, 
            isBeta:false
        },
    ]
    return(
        <section className='application-landing-page' style={{margin:'24px 0 0 80px'}}>
             <DeploymentMessage />
            {applications.filter(item => !item.access).length ?
                <Paper square style={{marginBottom:'10px'}}>
                    <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} aria-label="simple tabs example">
                        <Tab style={{textTransform:'none'}} label="ACTIVE APPLICATION(s)" value='active' />
                        <Tab style={{textTransform:'none'}} label="INACTIVE APPLICATION(s)" value='inactive' />
                    </Tabs>
                </Paper>:null
            }
            { tabIndex === 'active' ?
                <>
                    {(()=>{
                        const activeApps = applications.filter(item => item.access);
                        if(activeApps.length){
                            return(
                                <div>
                                    <Grid container spacing={2}>
                                        {activeApps.map((app) => 
                                            <AppsCard item={app} />
                                        )}
                                    </Grid>
                                </div>
                            )
                        }else{
                            return (
                                <div style={{textAlign:'center', padding:'20px 0'}}>
                                    <EmptyCollection title='No active application access ' />
                                </div>
                            )
                        }
                    })()}
                </>:null
            }
            { tabIndex === 'inactive' ?
                <>
                    {(()=>{
                        const inActiveApps = applications.filter(item => !item.access);
                        if(inActiveApps?.length){
                            return(
                                <div>
                                    <Grid container spacing={2}>
                                        {inActiveApps.map((app) => 
                                            <AppsCard item={app} />
                                        )}
                                    </Grid>
                                </div>
                            )
                        }else{
                            return null
                        }
                    })()}
                </>:null
            }
        </section>
    )
}
export default ApplicationsAccess;