import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Paper } from '@material-ui/core';
import React from 'react';
const ConsoleMatrix = ({roleCrud, disabled, onChangePermission}) => {
    const checkForKey = (key, arr) => {
        let permissionItem = null;
        arr.map((item) => {
            if(item.hasOwnProperty(key.toLowerCase())){
                permissionItem = item;
            }
        })
        return permissionItem;
    }
    return(
        <div>
            {roleCrud.item.application.map((application, appIndex) => 
                <Paper className='role-matrix' key={appIndex}>
                    <h4 className='feature-name'>{application.name}</h4>
                    <div style={{overflow:'auto'}}>
                        <Table className='mui-table-format'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Feature(s)</TableCell>
                                    {roleCrud.item.header.map((hd, hdIndex) => <TableCell align='center' key={hdIndex}>{hd.name}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { application.feature.map((feature, featureIndex) => 
                                    <TableRow key={featureIndex}>
                                        <TableCell style={{width:'160px'}}>
                                            <div style={{width:'160px'}}>{feature.name}</div>
                                        </TableCell>
                                        {roleCrud.item.header.map((hd, index)=>
                                            <TableCell align='center' key={index}>
                                                {(() => {
                                                    let permissionObj = checkForKey(hd.name, feature.permission);
                                                    if(permissionObj){
                                                        return <Checkbox disabled={permissionObj.disable || disabled} checked={permissionObj[hd.name.toLowerCase()]} color='primary' name='' size='small' onChange={(e) => onChangePermission(e.target.checked, appIndex, featureIndex, index, permissionObj, hd.name, checkForKey('Read', feature.permission))} />
                                                    }else{
                                                        return 'NA'
                                                    }
                                                })()}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            )}
        </div>
    )
}
export default ConsoleMatrix;