import React, { useState, useEffect } from 'react';
import { Paper, Button, Dialog, Grid, TextField, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableBody, TableCell, CircularProgress, TableRow, Tabs, Tab, FormControlLabel, Checkbox } from '@material-ui/core';
import { checkApiStatus } from '../../utils_v2';
import { globalGetService, globalPostService } from '../../../globalServices';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PageLoader } from '../../shared_elements';
export default function TemplateAddEdit({ templateSelection, toggleModalFn, newFolderData, currentPath, assetData, mainassetInfoData, submitWithTemplate, assemblyData, templatPayload, setDefaultTemplate, templateData }) {
    const [templates, setTemplates] = useState([])
    const [checkedFolders, setCheckedFolders] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [maxObj, setMaxObj] = useState(0);
    const [currentTemplate, setSelectedTemplate] = useState(templateData);
    const [importCrud, setImportCrud] = useState({ template: null });
    const [templateCrud, setTemplateCrud] = useState({ template: null });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        getTemplateList()
    }, [])
    useEffect(() => {
        if (templateCrud.length > 0) {
            setMaxObj(findMaxDepth(templateCrud));
        }
    }, [templateCrud]);

    const getTemplateList = () => {
        // let query = assemblyData?.asset_type == 2 ?  {engine_types:assemblyData?.assembly?.asset_type?.id}:  assemblyData?.asset_type == 3 ? {apu_types:assemblyData?.assembly?.asset_type?.id}: null
        let query={ }
        globalGetService(`records/v2/lessor-folder-template/`, {...query, asset_type:1, dropdown: true})
            .then(response => {
                if (checkApiStatus(response)) {
                    setTemplates(response.data.data.list)
                    if(assetData?.data?.templateDetails?.id){
                        if(templateData?.folderDetails?.length > 1){
                            setTemplateCrud(templateData?.selectedTemplate);
                            setCheckedFolders(templateData?.folderDetails)
                            setImportCrud({template:response.data.data.list?.find(item => item?.id == templateData?.templateDetails?.id)})
                        }
                        else{
                        onChangeTemplate(assetData?.data?.templateDetails, 'apicall')
                        }
                    }
                }
            });
    }

    const onChangeTemplate = (template, apiCall) => {
        setLoading(true)
        setImportCrud({ template });
            globalPostService(`records/v2/get-lessor-folder-template/`, { ...templatPayload, template_id: template?.id })
            .then(response => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    let responseData = response.data?.data?.folderDetails || []
                    setTemplateCrud(responseData);
                    setSelectedTemplate(response.data.data)
                    responseData.map(item=> onFieldChange(item, item?.is_checked,apiCall))
                }
            })
    }
    const onFieldChange = (folder, checked, apiState) => {
        // const updatedCheckedFolders = [...checkedFolders];
        let initialData = [...checkedFolders]
        if(apiState == 'dropdown'){
            initialData =[]
        }
        const updatedCheckedFolders = initialData;
        const toggleCheck = (folder, isChecked) => {
            const folderIndex = updatedCheckedFolders.findIndex(item => item.folder_name === folder.folder_name && item?.parent_folder[0] ==folder?.parent_folder[0] ); 
            const folderData = {
                is_checked: isChecked,
                level: folder?.level,
                parent_folder: folder?.parent_folder,
                naming_conventions: folder?.naming_conventions,
                folder_name: folder?.folder_name,
                childrens: folder?.childrens
            };

            if (folderIndex === -1) {
                updatedCheckedFolders.push(folderData);
            } else {
                updatedCheckedFolders[folderIndex] = folderData;
            }
            if(apiState == 'checkbox'){
                folder.childrens.forEach(child => toggleCheck(child, isChecked));
            }
            else{
                folder.childrens.forEach(child => toggleCheck(child, child?.is_checked));
            }
        };
        toggleCheck(folder, checked);
        if(apiState== 'apicall' ||  apiState =='dropdown' || ( checkedFolders?.length < 1 && assetData?.apiCall == 'apicall' && apiState != 'checkbox')){
            setCheckedFolders(prevState=> ([...prevState, ...updatedCheckedFolders]))
        }
        else{
            setCheckedFolders(updatedCheckedFolders);
        }
    };
    
    const onSubmitData = () => {
        let payload = {
            folderDetails:checkedFolders,
            selectedTemplate:templateCrud,
            templateDetails: importCrud.template,
            recordsNewFolderName: newFolderData,
            recordsParentFolder: currentPath,
            majorAssembly: true,
            majorAssemblyAsset: assemblyData?.assembly,
            majorAssemblyAssetType: assemblyData?.asset_type,
            asset_type: mainassetInfoData?.asset_type?.type,
            asset: { serial_number: mainassetInfoData?.serial_number, id: mainassetInfoData?.id }
        }
        setDefaultTemplate(currentTemplate)
        submitWithTemplate(payload, importCrud)
    };
    const findMaxDepth = (folders) => {
        let maxDepth = 0;
        const traverse = (folders, depth) => {
            folders.forEach((folder) => {
                if (folder.childrens.length > 0) {
                    traverse(folder.childrens, depth + 1);
                } else {
                    maxDepth = Math.max(maxDepth, depth);
                }
            });
        };
        traverse(folders, 1);
        return maxDepth;
    };

    const renderRows = (folders, depth = 1, parentIndex = '') => {
        const maxDepth = findMaxDepth(templateCrud);
        return folders.flatMap((folder, index) => {
            const rows = [];
            const uniqueKey = `${parentIndex}-${index}-${folder.folder_name}`; // Generate unique key
            const indent = Array.from({ length: depth - 1 }).map(() => (
                <TableCell key={Math.random()} />
            ));
            rows.push(
                <TableRow key={uniqueKey}>
                    {indent}
                    <TableCell>
                        <FormControlLabel control={<Checkbox size="small" color='primary' checked={checkedFolders.find(item => (item.folder_name === folder.folder_name) && item?.is_checked == true && (item.parent_folder?.length ? item.parent_folder[0] === folder.parent_folder[0]:true ) )?.is_checked  || false} onChange={(e) => onFieldChange(folder, e.target.checked, 'checkbox')} name="sub_folder" />} label={<p style={{ fontSize: '13px' }}>{folder.folder_name}</p>} />
                    </TableCell>
                    {Array.from({ length: maxDepth - depth }).map((_, i) => (
                        <TableCell key={`empty-${uniqueKey}-${i}`} />
                    ))}
                    <TableCell>{folder.naming_conventions}</TableCell>
                </TableRow>
            );
            if (folder.childrens.length > 0) {
                rows.push(...renderRows(folder.childrens, depth + 1, uniqueKey));
            }
            return rows;
        });
    };
    const changeTemplateFn = (value) => {
        setImportCrud({})
        setCheckedFolders([])
        if (value?.id) {
            setLoading(true)
            setTimeout(() => { onChangeTemplate(value, 'dropdown') }, 700);
        }
        else { onChangeTemplate(value) }
    }
    return (
        <>
        {isLoading ? <PageLoader /> : null}
        <Dialog
            open={templateSelection}
            onClose={() => { toggleModalFn(false) }}
            className='console-management-modal'
            maxWidth={false}
        >
            <DialogTitle id="scroll-dialog-title">
                Template
            </DialogTitle>
            <DialogContent dividers={true} >
                <div style={{ minHeight: '600px', minWidth: '500px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                style={{ marginBottom: '20px' }}
                                options={templates}
                                getOptionLabel={option => option.name}
                                id="template"
                                value={importCrud.template}
                                onChange={(e, value) => changeTemplateFn(value)}
                                renderInput={params => <TextField required {...params} label="Select Template" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} style={{ display: 'flex' }}>
                            {importCrud.template ?
                                <FormControlLabel control={<Checkbox size="small" color='primary' checked={includeFolder} onChange={(e) => setIncludeFolder(e.target.checked)} name="sub_folder" />} label={<p style={{ fontSize: '13px' }}>Include selected Folder</p>} />
                                : null}
                        </Grid> */}
                    </Grid>
                    <Grid item md={12}>
                        {templateCrud?.length ?
                         <div style={{overflow:"scroll", width:`${(40)+(maxObj * 20)}%`, minWidth:"100%"}}>
                            <Table className='mui-table-format'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell >Main Folders</TableCell>
                                        {
                                            Array.from({ length: maxObj - 1 }).map((item, index) => (
                                                <TableCell >Sub Folder {index + 1} </TableCell>
                                            ))
                                        }
                                        <TableCell width='200px'>Naming Conventions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {templateCrud?.length ?
                                        renderRows(templateCrud)
                                        : null
                                    }
                                </TableBody>
                            </Table>
                            </div>
                             :
                            <Paper style={{ textAlign: 'center', padding: '20px' }}>
                                {isLoading ?
                                    <CircularProgress size={24} /> :
                                    'Choose template to you wish to select.'
                                }
                            </Paper>
                        }
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={(checkedFolders.filter(item => item?.is_checked == true)?.length == 0) || isLoading} onClick={()=>{onSubmitData();toggleModalFn()}} color='primary' size='small' variant='contained'>Save</Button>
            </DialogActions>
        </Dialog>
        </>
    )
}
