import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { MajorAssembliesHD, MajorAssembliesList } from '../components'
import { globalGetService, globalExportService, globalPostService } from '../../../globalServices';
import { checkApiStatus, checkPermission, getLocalStorageInfo } from '../../utils_v2';
import { downloadFileType } from '../../../utils'
import { Pagination, FilterComponent, STableLoader, PageLoader, ExportMenu, EmptyCollection } from '../../shared_elements';
import { Table, TableBody, Paper, Grid, Button } from '@material-ui/core';
import { assetFilterOptions, leaseStatus } from '../';
import DeploymentMessage from '../../../shared/components/DeploymentMessage';
const MajorAssemblies = ({}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [lessee, setLessee] = useState([]);
    const [apuList, setApuList] = useState([]);
    const [engineTypes, setEngineTypes] = useState([]);
    const [majorAssembliesInfo, setMajorAssembliesInfo] = useState({list:[], pagination:{}});
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const [pltConstants, setPltConstants] = useState([]);
    useEffect(() => {
        getMajorAssemblies({...filter, per_page:50}, 'skeletonLoader');
        getAircraftTypes();
        getEngineTypes();
        getLessee();
        getPlatformConstants();
        getApuList();
    },[]);
    const getAircraftTypes = () => {
        globalGetService(`console/aircraft-types/`,{distinct:1})
        .then(response => {
            if(checkApiStatus(response)){
                setAircraftTypes(response.data.data.aircraftTypes);
            }
        })
    }
    const getPlatformConstants = () => {
        globalPostService('console/get-constants/',{constant_types:['asset_status', 'asset_sub_status', 'credit_classification', 'engine_position', 'lg_position']})
        .then(response => {
            if(checkApiStatus(response)){
                setPltConstants(response.data.data)
            }
        })
    }
    const getLessee = () => {
        globalGetService(`console/lessees/?distinct=1`)
        .then(response => {
            if(checkApiStatus(response)){
                setLessee(response.data.data.lessees)
            }
        })
    }
      const getApuList = () => {
        globalGetService(`/console/apu-types/`)
        .then(response => {
            if(checkApiStatus(response)){
                setApuList(response.data.data?.apu_types)
            }
        })
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`,{distinct:1})
        .then(response => {
            if(checkApiStatus(response)){
                setEngineTypes(response.data.data.engineTypes)
            }
        })
    }
    const getMajorAssemblies = (query, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true):setLoading(true);
        globalGetService(`console/major_assemblies_list/?major_assemblies=True`, query)
        .then(response => {
            if(checkApiStatus(response)){
                setMajorAssembliesInfo(response.data.data);
                delete query.page
                delete query.per_page
                setFilter(query);
            }else{

            }
            loaderType === 'skeletonLoader' ? setSkeletonLoader(false):setLoading(false);
        });
    }

    const exportMajorAssemblies = (extension) => {
        setLoading(true);
        globalExportService(`console/major_assemblies_list/`, {...filter, major_assemblies:'True', download:extension})
        .then(response => {
            setLoading(false);
            downloadFileType(response, 'Major Assemblies.', extension)
        });
    }
    let filterOptions = Object.assign({}, assetFilterOptions);
    const { jacLeaseStatus, defaultLeaseStatus, airAsiaLeaseStatus } = leaseStatus;
    filterOptions = {
        ...filterOptions,
        aircraft_type:{
            ...filterOptions.aircraft_type,
            options:aircraftTypes
        },
        engine_type:{
            ...filterOptions.engine_type,
            options:engineTypes
        },
        lessee:{
            ...filterOptions.lessee,
            options:lessee
        },
        apu_type:{
            ...filterOptions.apu_type,
            options :apuList
        },
        status:{
            ...filterOptions.status,
            options: getLocalStorageInfo().defaultLessor.id === 348 ? airAsiaLeaseStatus : getLocalStorageInfo().defaultLessor.id === 442 ? jacLeaseStatus : defaultLeaseStatus
        },
        sub_status:{
            ...filterOptions.sub_status,
            options:pltConstants.filter(item => item.type === 'asset_sub_status')
        }
    }
    return(
        <section className='fleets-management' style={{margin:'24px 0 0 80px'}}>
             <DeploymentMessage />
            <div>
                { skeletonLoader ? <STableLoader count={10} />:
                <>
                    <Paper square style={{padding:'5px 10px'}}>
                        <Grid container spacing={1} alignItems='center'>
                            <Grid item md={9}>
                                <FilterComponent 
                                    filter={filter} 
                                    filterMenu={filterOptions}
                                    getResponseBack={(applyFilter) => getMajorAssemblies({...applyFilter, page:1, per_page:majorAssembliesInfo.pagination.per_page}, 'pageLoader')}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <ul className='list-inline' style={{float:'right'}}>
                                    {majorAssembliesInfo.list.length && checkPermission('technical_specs','major_assemblies','EXP') ?
                                        <li className='list-inline-item'>
                                            <ExportMenu 
                                                exportReportFn={(file) => exportMajorAssemblies(file.extension)}
                                                files={[{title:'PDF', extension:'pdf'}, {title:'EXCEL', extension:'xls'}]}
                                            />
                                        </li>:null
                                    }
                                    
                                </ul>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper className='major-assemblies-list'>
                        <div style={{height:!majorAssembliesInfo.list.length ? 'auto':`${window.innerHeight-200}px`, overflow:'auto'}}>
                            <Table className='mui-table-format'>
                                <MajorAssembliesHD />
                                <TableBody>
                                    {majorAssembliesInfo.list.map((item,index) =>
                                        <MajorAssembliesList 
                                            item={item}
                                            key={index}
                                        />
                                    )}
                                </TableBody>
                            </Table>
                            {!majorAssembliesInfo.list.length ? <div style={{textAlign:'center'}}><EmptyCollection title="No records found" /></div>:null}
                        </div>
                        <Pagination 
                            pagination={majorAssembliesInfo.pagination}
                            onChangePage={(event, newPage) => getMajorAssemblies({...filter, page:newPage+1, per_page: majorAssembliesInfo.pagination.per_page}, 'pageLoader')}
                            onChangeRowsPerPage={(event) => getMajorAssemblies({...filter, page:1, per_page: event.target.value}, 'pageLoader')}
                        />
                    </Paper>
                </>
                }
            </div>
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default MajorAssemblies;