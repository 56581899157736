import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, Button, Grid, TextField, FormControlLabel, Checkbox, Tooltip } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import LeaseStatusError from './LeaseStatusError';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { assetTypeValues, backendDateFormat, fieldDateFormat } from '../../../constants';
import MSNOnOffWing from "./MSNOnOffWing";
import ErrorTitled from "./ErrorTitled";
import { GoogleLocationField, PageLoader, LesseeCreate, STableLoader } from "../../shared_elements";
import { globalGetService, globalPostService, globalPutService } from "../../../globalServices";
import { checkApiStatus, checkPermission, getLocalStorageInfo, replaceEmptyNumber } from '../../utils_v2';
import { getPermissionKey, offWingObj } from "..";
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { regexConstants } from '../../../constants/regEx';
import { trackActivity } from "../../../utils/mixpanel";
import ServerErrorModal from "./ServerErrorModal";
import { jetStreamInstance } from "../../../shared/components";
import config from "../../../config";
const ALPDetails = ({ params, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [error, setError] = useState({});
    const [basicDetails, setBasicDetails] = useState({});
    const [lgSubAssembly, setLgSubAssembly] = useState([]);
    const [regions, setRegions] = useState([]);
    const [assets, setAssets] = useState([]);
    const [apuTypes, setApuTypes] = useState([]);
    const [operators, setOperators] = useState([])
    const [engineTypes, setEngineTypes] = useState([]);
    const [lessees, setLessees] = useState([]);
    const [lessors, setLessors] = useState([])
    const [pltConstants, setPltConstants] = useState([])
    const [serverError, setServerError] = useState({ modal: false, data: null })
    const [serverErrorMdl, setErrorModal] = useState({ modal: false });
    const [activeLeaseModal, setActiveLeaseModal] = useState({ modal: false, error: null });

    useEffect(() => {
        getBasicDetails('skeletonLoader');
    }, []);
    const getDropdownData = () => {
        getRegionOfOperations();
        getLessees();
        getLessors();
        getPltConstants();
        getApuTypes();
        getEngineTypes();
        getAssets();
        getOperators();
        if (params.type === 'lg' && basicDetails?.position?.value) {
            getLgSubAssembly(basicDetails?.position?.value);
        }
    }
    const getBasicDetails = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/basic_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setBasicDetails(response.data.data);
                    //getResponseBack()
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const getAssets = () => {
        globalGetService('console/list/?asset_type=1&dropdown=true')
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssets(response.data.data.asset)
                }
            })
    }
    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`, { lite: true, aircraft_type: basicDetails?.stand_alone ? null : assetInfoData?.msn?.aircraft_type?.id || null })
            .then(response => {
                if (checkApiStatus(response)) {
                    setEngineTypes(response.data.data.engineTypes)
                }
            })
    }
    const getLgSubAssembly = (position) => {
        globalGetService(`console/lg_sub_assemblies_position/`, { position: position, aircraft_type_id: assetInfoData?.msn?.aircraft_type?.id })
            .then(response => {
                if (checkApiStatus(response)) {
                    setLgSubAssembly(response.data.data);
                }
            })
    }
    const getApuTypes = () => {
        globalGetService(`console/apu-types/`,{aircraft_type: basicDetails?.stand_alone ? null : assetInfoData?.msn?.aircraft_type?.id || null})
            .then(response => {
                if (checkApiStatus(response)) {
                    setApuTypes(response.data.data.apu_types);
                }
            })
    }
    const getRegionOfOperations = () => {
        globalGetService(`console/regions/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setRegions(response.data.data.region_list)
                }
            })
    }
    const getLessees = () => {
        globalGetService(`console/lessees/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessees(response.data.data.lessees)
                }
            })
    }
    const getOperators = () => {
        globalGetService(`console/operators/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setOperators(response.data.data.operators)
                }
            })
    }
    const getLessors = () => {
        globalGetService(`console/owner/?dropdown=true&lessor_use=true`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessors(response.data.data)
                }
            })
    }
    const getPltConstants = () => {
        globalPostService('console/get-constants/', { constant_types: ['asset_status', 'asset_sub_status', 'lg_position', 'engine_position', 'asset_ownership_type', 'off_wing_status', 'shop_visit_type', 'workscope_status', 'sv_report_status', 'sv_requirement'] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setPltConstants(response.data.data)
                }
            })
    }
    const onFieldChange = (key, value, offWingType) => {
        if (offWingType) {
            setBasicDetails({
                ...basicDetails, [offWingType]: {
                    ...basicDetails[offWingType],
                    [key]: value
                }
            })
        } else {
            if (params.type === 'lg' && key === 'position') {
                if (value) {
                    getLgSubAssembly(value.value);
                } else {
                    setLgSubAssembly([]);
                }
            }
            if (key === 'engine_type') {
                if (value) {
                    setBasicDetails({ ...basicDetails, [key]: value, manufacturer: value.manufacturer });
                } else {
                    setBasicDetails({ ...basicDetails, [key]: value, manufacturer: null });
                }
            } else if (key === 'off_wing_status') {
                setBasicDetails({ ...basicDetails, [key]: value, off_wing_detail: offWingObj });
            } else if (key === 'on_wing_status') {
                setBasicDetails({ ...basicDetails, [key]: value, off_wing_detail: offWingObj, off_wing_status: null, on_wing_msn: '', on_wing_lessor: '' });
            } else {
                setBasicDetails({ ...basicDetails, [key]: value });
            }
        }
    }

    const onEditBasicDetails = () => {
        let dom = params.type === 'engine' ? assetInfoData?.manufacturing_date : assetInfoData?.date_of_manufacture;
        let validationInputs = {
            sold_out_date: basicDetails?.sold_out_date ? moment(basicDetails.sold_out_date).isSameOrAfter(dom, 'day') ? '' : "Date of Sale cannot be lesser than Date Of Manufacture" : '',
            // SMBC 6 Month Purchase Date Condition 
            purchase_date: params.type === 'engine' ? moment(basicDetails?.purchase_date).isValid() ?(smbcInstance?(moment(basicDetails?.purchase_date).isSameOrAfter(moment(basicDetails?.manufacturing_date).subtract(6, 'months'))) :(moment(basicDetails?.purchase_date).isSameOrAfter(basicDetails?.manufacturing_date, 'day'))) ? moment(basicDetails?.purchase_date).isSameOrBefore(moment(), 'day') ? '' : "Date of Purchase cant't be future Date" :(smbcInstance?  "Date of Purchase can't be less than 6 months before Date of Manufacture" :"Date of Purchase cant't be lesser than Date Of Manufacture" ): '' : '',
            tsn_at_removal: !basicDetails?.off_wing_detail?.tsn_at_removal?.toString()?.trim().length >= 0 ? '' : 'Please enter TSN of Engine Removal',
            country: basicDetails?.country?.trim()?.length ? '' : 'Please enter Country',
            lessor_name: basicDetails?.lessor_name ? '' : 'Please select Lessor',
            owner: basicDetails?.owner ? '' : 'Please select Owner',
            status: basicDetails?.status ? '' : 'Please select Lease Status',
            owner_portfolio: basicDetails?.ownership_type?.value === 2 && !jetStreamInstance ? basicDetails?.owner_portfolio ? '' : 'Please select Portfolio' : '',
            lessee: basicDetails?.status?.value === 1 ? basicDetails?.lessee ? '' : 'Please select Lessee' : '',
            operator: basicDetails?.status?.value === 1 && basicDetails.same_operator_lessee === false ? basicDetails?.operator ? '' : 'Please select Operator' : '',
            off_wing_status: basicDetails?.on_wing_status === false ? basicDetails?.off_wing_status ? '' : 'Please select Off Wing Condition' : '',
            manufacturing_date: params.type === 'engine' ? moment(basicDetails?.manufacturing_date).isValid() ? moment(basicDetails.manufacturing_date).isValid() && moment(basicDetails?.manufacturing_date).isSameOrBefore(moment(), 'day') ? '' : "Date of Manufacture cannot be future date" : 'Please enter Date of Manufacture' : '',
            date_of_manufacture: params.type !== 'engine' ? moment(basicDetails?.date_of_manufacture).isValid() ? moment(basicDetails.date_of_manufacture).isValid() && moment(basicDetails?.date_of_manufacture).isSameOrBefore(moment(), 'day') ? '' : "Date of Manufacture cannot be future date" : 'Please enter Date of Manufacture' : '',
            serial_number: params.type !== 'engine' ? basicDetails?.serial_number?.trim()?.length ? '' : 'Please enter Serial Number' : '',
            part_number: (params.type === 'apu' || params.type === 'lg') ? basicDetails?.part_number?.trim()?.length ? '' : 'Please enter Part Number' : '',
            apu_type: params.type === 'apu' ? basicDetails?.apu_type ? '' : 'Please select APU Type' : '',
            position: (basicDetails?.status?.value === 1) && (params.type === 'lg' || params.type === 'engine') ? basicDetails?.position ? '' : 'Please select Position' : '',
            engine_type: params.type === 'engine' ? basicDetails?.engine_type ? '' : 'Please select Engine Type' : '',
            esn: params.type === 'engine' ? basicDetails?.esn?.trim()?.length ? '' : 'Please enter ESN' : '',
            model: params.type === 'propeller' ? basicDetails?.model?.trim()?.length ? '' : 'Please enter Model' : '',
            installed_position: params.type === 'propeller' ? basicDetails?.installed_position ? '' : 'Please select Installed Position' : '',
            sub_status: getLocalStorageInfo()?.defaultLessor?.id === 242 ? basicDetails.sub_status ? '' : 'Please select Sub Status' : '',
            titled_aircraft: basicDetails?.titled_aircraft ? '' : "Please select Titled MSN"
        }
        if (basicDetails?.sold_out_date && !moment(basicDetails?.sold_out_date).isSameOrBefore(moment(), 'day')) {
            validationInputs = {
                ...validationInputs,
                sold_out_date: "Date of Sale cannnot be future date"
            }
        }
        if (basicDetails?.status?.value === 1 && basicDetails?.lessee && basicDetails.same_operator_lessee === false && basicDetails?.operator) {
            if (basicDetails.lessee.name === basicDetails.operator.name) {
                validationInputs = {
                    ...validationInputs,
                    operator: 'Operator Should be different from Lessee'
                }
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let payload = Object.assign({}, basicDetails);
            payload = {
                ...payload,
                off_wing_detail: replaceEmptyNumber(payload.off_wing_detail, ['tsn_at_removal', 'csn_at_removal', 'reason_for_removal', 'type_of_shop_visit', 'work_scope_status', 'shop_visit_name', 'shop_visit_location', 'shop_visit_requirement', 'selected_shop', 'off_wing_location', 'other_aircraft_msn'])
            }
            setLoading(true);
            setError({})
            globalPutService(`console/v2/asset/${params.type}/${params.aircraft_slug}/basic_details/`, payload)
                .then(response => {
                    setLoading(false);
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        trackActivity('Item Edited', { page_title: 'Basic Details', asset_type: assetTypeValues[assetInfoData?.asset_type?.type]?.label, asset_name: assetInfoData?.serial_number, event_type: 'Basic Details Edited', event_desc: 'Edited Basic Details data from Edit Basic Details form' });
                        setEdit(false);
                        getResponseBack()
                    }  else if (response.data.statusCode === 422) {
                        setErrorModal({ modal: true, data: response })
                    }else {
                        if (response.data?.statusCode === 406) {
                            setServerError({ modal: true, data: response.data });
                        }
                        if (response.data?.statusCode === 409) {
                            setActiveLeaseModal({ modal: true, error: response.data })
                        }
                    }
                })
        } else {
            setError(validationInputs);
            enqueueSnackbar('Mandatory field(s) are missing', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });

        }
    }
    const smbcInstance =  getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 );
    return (
        <div style={{ position: 'relative' }}>
            <div className="console-forms-fields" style={{ height: `${window.innerHeight - 220}px`, padding: '10px', overflow: 'auto' }}>
                <p style={{ textAlign: 'right', height: '20px' }}>
                    {checkPermission('technical_specs', getPermissionKey(params.type), 'U') && !isEdit ?
                        <span style={{ cursor: 'pointer' }}>
                            <EditIcon onClick={() => { getDropdownData(); setEdit(true); }} fontSize="small" color='primary' />
                        </span> : null
                    }
                </p>
                {skeletonLoader ? < STableLoader count={4} /> : <>
                <Grid container spacing={1}>
                    {params.type === 'engine' ?
                        <>
                            <Grid item md={3}>
                                <Autocomplete
                                    disabled={true}
                                    options={[]}
                                    getOptionLabel={option => option.name}
                                    name="manufacturer"
                                    value={basicDetails?.manufacturer || null}
                                    renderInput={params => <TextField {...params} label="Manufacturer" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <Autocomplete
                                    disabled={!isEdit}
                                    options={engineTypes}
                                    getOptionLabel={option => option.name}
                                    name="engine_type"
                                    value={basicDetails?.engine_type || null}
                                    onChange={(e, value) => { onFieldChange('engine_type', value); setError({ ...error, 'engine_type': '' }); }}
                                    renderInput={params => <TextField error={error?.engine_type} helperText={error?.engine_type || ''} required {...params} label="Engine Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    required
                                    disabled={!isEdit}
                                    name='esn'
                                    label={
                                        <span>
                                            ESN
                                            <Tooltip title='Engine Serial Number' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                        </span>
                                    }
                                    value={basicDetails?.esn || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('esn', e.target.value)}
                                    error={error?.esn}
                                    helperText={error?.esn || ''}
                                    onFocus={() => setError({ ...error, 'esn': '' })}
                                    InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                    inputProps={{ maxLength: 25 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={3}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        required
                                        disabled={!isEdit}
                                        margin="normal"
                                        name="manufacturing_date"
                                        label="Date of Manufacture"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        minDate={moment().subtract(25, 'years')}
                                        value={basicDetails?.manufacturing_date || null}
                                        onChange={(data, value) => { onFieldChange('manufacturing_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'manufacturing_date': '' }) }}
                                        error={error?.manufacturing_date}
                                        helperText={error?.manufacturing_date || ''}
                                        onFocus={() => setError({ ...error, 'manufacturing_date': '' })}
                                        inputVariant='outlined'
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={3}>
                                <Autocomplete
                                    disabled={!isEdit}
                                    options={smbcInstance?pltConstants.filter(optionItem => optionItem.type === 'engine_position').filter((item)=>[0,1,2].includes(item.value)):pltConstants.filter(optionItem => optionItem.type === 'engine_position')}
                                    getOptionLabel={option => option.label}
                                    name="position"
                                    value={basicDetails?.position || null}
                                    onChange={(e, value) => { onFieldChange('position', value); setError({ ...error, 'position': '' }); }}
                                    renderInput={params => <TextField error={basicDetails?.status?.value === 1 && error?.position ? true : false} helperText={basicDetails?.status?.value === 1 && error?.position || ''} required={basicDetails?.status?.value === 1} {...params} label="Position" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                        </> : null
                    }

                    {params.type !== 'engine' ?
                        <>
                            <Grid item md={3}>
                                <TextField
                                    disabled={!isEdit}
                                    name='manufacturer'
                                    label='Manufacturer'
                                    value={basicDetails?.manufacturer || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('manufacturer', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    required
                                    disabled={!isEdit}
                                    name='serial_number'
                                    label='Serial Number'
                                    value={basicDetails?.serial_number || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('serial_number', e.target.value)}
                                    error={error?.serial_number}
                                    helperText={error?.serial_number || ''}
                                    onFocus={() => setError({ ...error, 'serial_number': '' })}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 25 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    required={params.type === 'propeller' ? false : true}
                                    disabled={!isEdit}
                                    name='part_number'
                                    label='Part Number'
                                    value={basicDetails?.part_number || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('part_number', e.target.value)}
                                    error={error?.part_number}
                                    helperText={error?.part_number || ''}
                                    onFocus={() => setError({ ...error, 'part_number': '' })}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 25 }}
                                    variant='outlined'
                                />
                            </Grid>
                        </> : null
                    }

                    {params.type === 'apu' ?
                        <Grid item md={3}>
                            <Autocomplete
                                disabled={!isEdit}
                                options={apuTypes}
                                getOptionLabel={option => option.name}
                                name="apu_type"
                                value={basicDetails?.apu_type || null}
                                onChange={(e, value) => { onFieldChange('apu_type', value); setError({ ...error, 'apu_type': '' }); }}
                                renderInput={params => <TextField error={error?.apu_type} helperText={error?.apu_type || ''} required {...params} label="APU Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid> : null
                    }
                    {params.type === 'lg' ?
                        <>
                            <Grid item md={3}>
                                <Autocomplete
                                    disabled={!isEdit}
                                    options={pltConstants.filter(optionItem => optionItem.type === 'lg_position')}
                                    getOptionLabel={option => option.label}
                                    name="position"
                                    value={basicDetails?.position || null}
                                    onChange={(e, value) => {onFieldChange('position', value); setError({ ...error, 'position': '' })}}
                                    renderInput={params => <TextField required={basicDetails?.status?.value === 1} error={error?.position} helperText={error?.position || ''} {...params} onFocus={()=>setError({ ...error, 'position': '' })} label="LG Position" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                            <Grid item md={3}>
                                <Autocomplete
                                    disabled={!isEdit}
                                    options={lgSubAssembly}
                                    getOptionLabel={option => option.name}
                                    name="sub_assembly"
                                    value={basicDetails?.sub_assembly || null}
                                    onChange={(e, value) => onFieldChange('sub_assembly', value)}
                                    renderInput={params => <TextField {...params} label="Sub Assembly" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                        </> : null
                    }

                    {params.type === 'propeller' ?
                        <>
                            <Grid item md={3}>
                                <TextField
                                    required
                                    disabled={!isEdit}
                                    name='model'
                                    label='Model'
                                    value={basicDetails?.model || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => onFieldChange('model', e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 50 }}
                                    error={error?.model}
                                    helperText={error?.model || ''}
                                    onFocus={() => setError({ ...error, 'model': '' })}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={3}>
                                <Autocomplete
                                    disabled={!isEdit}
                                    options={pltConstants.filter((optionItem) => optionItem.type === 'engine_position')}
                                    getOptionLabel={option => option.label}
                                    name="installed_position"
                                    value={basicDetails?.installed_position || null}
                                    onChange={(e, value) => { onFieldChange('installed_position', value); setError({ ...error, 'installed_position': '' }) }}
                                    renderInput={params => <TextField required={basicDetails?.status?.value === 1} error={error?.installed_position} helperText={error?.installed_position || ''} {...params} label="Installed Position" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                        </> : null
                    }
                    {params.type !== 'engine' ?
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="date_of_manufacture"
                                    label="Date of Manufacture"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, 'years')}
                                    value={basicDetails?.date_of_manufacture || null}
                                    onChange={(data, value) => { onFieldChange('date_of_manufacture', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'date_of_manufacture': '' }) }}
                                    error={error?.date_of_manufacture}
                                    helperText={error?.date_of_manufacture || ''}
                                    onFocus={() => setError({ ...error, 'date_of_manufacture': '' })}
                                    inputVariant='outlined'
                                />
                            </MuiPickersUtilsProvider>
                        </Grid> : null
                    }
                    {params.type === 'engine' ?
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="purchase_date"
                                    label="Date of Purchase"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={smbcInstance?(moment(basicDetails?.manufacturing_date).subtract(6, 'months')):(basicDetails?.manufacturing_date)}
                                    value={basicDetails?.purchase_date || null}
                                    onChange={(data, value) => { onFieldChange('purchase_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'purchase_date': '' }) }}
                                    inputVariant='outlined'
                                    error={error?.purchase_date}
                                    helperText={error?.purchase_date || ''}
                                //onFocus={() => setError({ ...error, 'purchase_date': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid> : null}
                    <Grid item md={3}>
                        <TextField
                            required
                            disabled={!isEdit}
                            name='country'
                            label='Country'
                            value={basicDetails?.country || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('country', e.target.value)}
                            error={error?.country}
                            helperText={error?.country || ''}
                            onFocus={() => setError({ ...error, 'country': '' })}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 100 }}
                            variant='outlined'
                        />
                    </Grid>
                    {params.type === 'engine' ?
                        <Grid item md={3}>
                            <Autocomplete
                                disabled={!isEdit || !basicDetails?.stand_alone}
                                options={regions}
                                getOptionLabel={option => option.name}
                                name="region"
                                value={basicDetails?.region || null}
                                onChange={(e, value) => onFieldChange('region', value)}
                                renderInput={params => <TextField {...params} label="Region of Operation" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid> : null}
                    <Grid item md={3}>
                        {!isEdit ?
                            <TextField
                                disabled={true}
                                name='location'
                                label={
                                    <span>
                                        Location
                                        <Tooltip title='Input country of operation' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                value={basicDetails?.location || ''}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            /> :

                            <GoogleLocationField
                                onFieldChange={onFieldChange}
                                location={basicDetails?.location || ''}
                                label={
                                    <span>
                                        Location
                                        <Tooltip title='Input country of operation' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                            />}
                    </Grid>

                    <Grid item md={3}>
                        <Autocomplete
                            disabled={!isEdit}
                            options={lessors}
                            getOptionLabel={option => option.name}
                            name="lessor_name"
                            value={basicDetails?.lessor_name || null}
                            onChange={(e, value) => { onFieldChange('lessor_name', value); setError({ ...error, 'lessor_name': '' }) }}
                            renderInput={params => <TextField required error={error?.lessor_name} helperText={error?.lessor_name || ''} {...params} label="Lessor" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            disabled={!isEdit}
                            options={lessors}
                            getOptionLabel={option => option.name}
                            name="owner"
                            value={basicDetails?.owner || null}
                            onChange={(e, value) => { onFieldChange('owner', value); setError({ ...error, 'owner': '' }) }}
                            renderInput={params => <TextField required error={error?.owner} helperText={error?.owner || ''} {...params} label="Owner" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            disabled={!isEdit}
                            name='airport_code'
                            label='Airport Code'
                            value={basicDetails?.airport_code || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('airport_code', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            disableClearable
                            disabled={!isEdit}
                            options={[{ value: 1, label: 'Owned' }, { value: 2, label: 'Managed' }]}
                            getOptionLabel={option => option.label}
                            name="ownership_type"
                            value={basicDetails?.ownership_type || null}
                            onChange={(e, value) => onFieldChange('ownership_type', value)}
                            renderInput={params => <TextField required {...params} label="Ownership" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    {Object.keys(basicDetails).length && basicDetails?.ownership_type?.value === 2 && !jetStreamInstance?
                        <Grid item md={3}>
                            <Autocomplete
                                disabled={!isEdit}
                                options={lessors}
                                getOptionLabel={option => option.name}
                                name="owner_portfolio"
                                value={basicDetails?.owner_portfolio || null}
                                onChange={(e, value) => { onFieldChange('owner_portfolio', value); setError({ ...error, 'owner_portfolio': '' }) }}
                                renderInput={params => <TextField required error={error?.owner_portfolio} helperText={error?.owner_portfolio || ''} {...params} label="Portfolio" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid> : null
                    }

                    <Grid item md={3}>
                        <Autocomplete
                            disabled={(!isEdit || basicDetails?.status?.value === 5 || assetInfoData?.status?.value === 5) ? true : checkPermission('technical_specs', 'engine', 'STS') ? false : true}
                            options={getLocalStorageInfo()?.defaultLessor?.id !== 442 ? (pltConstants.filter(item => item?.type === 'asset_status').filter(item => item?.remarks === null).filter(item => item?.value !== 5)) : pltConstants.filter(item => item?.type === 'asset_status').filter(item => item?.value !== 5 && item?.value !== 3)}
                            getOptionLabel={option => option.label}
                            name="status"
                            value={assetInfoData?.status?.value === 5 ? assetInfoData?.status :basicDetails?.status || null}
                            onChange={(e, value) => { onFieldChange('status', value); setError({ ...error, 'status': '' }) }}
                            renderInput={params => <TextField required error={error?.status} helperText={error?.status || ''} {...params} label="Lease Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            disabled={!isEdit}
                            options={pltConstants.filter(item => item?.type === 'asset_sub_status')}
                            getOptionLabel={option => option.label}
                            name="sub_status"
                            value={basicDetails?.sub_status || null}
                            onChange={(e, value) => { onFieldChange('sub_status', value); setError({ ...error, 'sub_status': '' }) }}
                            renderInput={params => <TextField required={getLocalStorageInfo()?.defaultLessor?.id === 242} error={error?.sub_status} helperText={error?.sub_status || ''} {...params} label="Sub Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    {basicDetails?.status?.value === 4 ?
                        <>
                            <Grid item md={3}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!isEdit}
                                        margin="normal"
                                        name="sold_out_date"
                                        label="Date of Sale"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        minDate={params.type === 'engine' ? moment(assetInfoData?.manufacturing_date) : moment(assetInfoData?.date_of_manufacture)}
                                        value={basicDetails?.sold_out_date || null}
                                        onChange={(data, value) => { onFieldChange('sold_out_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'sold_out_date': '' }) }}
                                        inputVariant='outlined'
                                        error={error?.sold_out_date}
                                        helperText={error?.sold_out_date || ''}
                                        onFocus={() => setError({ ...error, 'sold_out_date': '' })}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    disabled={!isEdit}
                                    name='tsn_at_sold_date'
                                    label='TSN at Sale'
                                    value={basicDetails?.tsn_at_sold_date}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn_at_sold_date', e.target.value) : e.preventDefault()}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    disabled={!isEdit}
                                    name='csn_at_sold_date'
                                    label='CSN at Sale'
                                    value={basicDetails?.csn_at_sold_date}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('csn_at_sold_date', e.target.value) : e.preventDefault()}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                        </> : null
                    }
                    {basicDetails?.status?.value === 1 ?
                        <>
                            <Grid item md={3}>
                                <LesseeCreate
                                    options={lessees}
                                    required={true}
                                    paramsKey='lessee'
                                    optionKey='name'
                                    label='Lessee'
                                    value={basicDetails?.lessee || null}
                                    onFieldChange={(e, paramsKey, newValue) => onFieldChange('lessee', newValue)}
                                    error={error.lessee}
                                    resetErrorKey={() => setError({ ...error, 'lessee': '' })}
                                    disabled={!isEdit}
                                />
                            </Grid>

                            {/* {<Grid item md={3}>
                                <Autocomplete
                                    disabled={!isEdit}
                                    options={lessees}
                                    getOptionLabel={option => option.name}
                                    name="lessee"
                                    value={basicDetails?.lessee || null}
                                    onChange={(e, value) => { onFieldChange('lessee', value); setError({ ...error, 'lessee': '' }) }}
                                    renderInput={params => <TextField required error={error?.lessee} helperText={error?.lessee || ''} {...params} label="Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>} */}
                            {(() => {
                                let options = [{ label: 'Yes', value: 1 }, { label: 'No', value: 2 }];
                                let optionValue = basicDetails?.same_operator_lessee ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
                                return (
                                    <Grid item md={3}>
                                        <Autocomplete
                                            disableClearable={true}
                                            disabled={!isEdit}
                                            options={options}
                                            getOptionLabel={option => option.label}
                                            name="same_operator_lessee"
                                            value={optionValue}
                                            onChange={(e, value) => onFieldChange('same_operator_lessee', value?.value === 1 ? true : false)}
                                            renderInput={params => <TextField {...params} label="Operator is Same as Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    </Grid>
                                )
                            })()}
                            <Grid item md={3}>
                                <Autocomplete
                                    disabled={!isEdit || basicDetails?.same_operator_lessee}
                                    options={operators}
                                    getOptionLabel={option => option.name}
                                    name="operator"
                                    value={basicDetails?.same_operator_lessee ? basicDetails?.lessee : basicDetails?.operator || null}
                                    onChange={(e, value) => { onFieldChange('operator', value); setError({ ...error, 'operator': '' }) }}
                                    renderInput={params => <TextField required={basicDetails?.same_operator_lessee === false} error={error?.operator} helperText={error?.operator || ''} {...params} label="Operator" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                        </> : null
                    }
                    {params.type === 'engine' ?
                        <>
                            <Grid item md={3}>
                                <TextField
                                    disabled={!isEdit}
                                    name='purchase_thrust'
                                    label='Purchased Thrust (Lbs)'
                                    value={basicDetails?.purchase_thrust || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('purchase_thrust', e.target.value) : e.preventDefault()}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    disabled={!isEdit}
                                    name='operating_thrust'
                                    label='Operating Thrust (Lbs)'
                                    value={basicDetails?.operating_thrust || ''}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? onFieldChange('operating_thrust', e.target.value) : e.preventDefault()}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                        </> : null
                    }
                    {params.type === 'engine' && getLocalStorageInfo()?.user?.permission?.contracts ?
                        <Grid item md={12}>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox size="small" disabled={!isEdit} checked={basicDetails?.match_csn_to_llp ? true : false} onChange={(e) => onFieldChange('match_csn_to_llp', e.target.checked)} color="primary" />}
                                label={
                                    <span className="flex-centered">
                                        <span>Match LLPs with Engine Utilization</span>
                                        <Tooltip title="Tick to update LLP's as per Engine Utilization" placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                labelPlacement="match_csn_to_llp"
                            />
                        </Grid> : null
                    }
                    <Grid item md={12}>
                        <TextField
                            disabled={!isEdit}
                            name='technical_disclaimer'
                            label='Technical Disclaimer'
                            value={basicDetails?.technical_disclaimer || ''}
                            fullWidth
                            multiline
                            rows={3}
                            margin="normal"
                            onChange={(e) => onFieldChange('technical_disclaimer', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 255 }}
                            variant='outlined'
                        />
                    </Grid>
                    {Object.keys(basicDetails).length ?
                        <Grid item md={12}>
                            <MSNOnOffWing
                                assets={assets}
                                error={error}
                                onResetError={(key) => setError({ ...error, [key]: '' })}
                                onFieldChange={onFieldChange}
                                basicDetails={basicDetails}
                                isEdit={isEdit}
                                pltConstants={pltConstants}
                            />
                        </Grid> : null
                    }
                </Grid>
                </>}
                {isEdit ? <div className="divider" style={{ height: '51px' }}></div> : null}
            </div>
            {isEdit ?
                <Paper square style={{ padding: '10px', borderTop: '1px solid #d7d7d7', position: 'absolute', bottom: '0', left: '0', width: '100%', background: '#fff', zIndex: 9 }}>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <Button onClick={() => { setError(''); setEdit(false); getBasicDetails() }} color="primary" size='small' variant="outlined">Cancel</Button>
                        </li>
                        <li className="list-inline-item">
                            <Button onClick={onEditBasicDetails} color="primary" size='small' variant="contained">SAVE</Button>
                        </li>
                    </ul>
                </Paper> : null
            }
            {serverError.modal ?
                <ErrorTitled
                    basicDetails={basicDetails}
                    serverError={serverError}
                    toggleModalFn={() => setServerError({ modal: false, data: null })}
                /> : null
            }
            {activeLeaseModal.modal ?
                <LeaseStatusError
                    activeLeaseModal={activeLeaseModal}
                    toggleModalFn={() => setActiveLeaseModal({ modal: false, error: null })}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
            {
                serverErrorMdl?.modal ?
                    <ServerErrorModal
                        serverError={serverErrorMdl}
                        toggleModalFn={() => setErrorModal({ modal: false })}
                    /> : null
            }
        </div>

    )
}
export default withRouter(ALPDetails);